import logo from './logo.svg';
import './App.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faInstagramSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import {Col, Row} from 'reactstrap'
import imagen from './images/fondoblanco.png'
function App() {
    return (
        <div className="App">

            <header className="App-header" >
                <div id="imagenContenedor">
                    <div style={{marginTop:'160px'}}>
                        <img  src={imagen} width="90%" height="100%" />
                    </div>
                </div>
                <div className="contenedorSubtitulo">
                   <div id="marginContenedorSubtitulo" >
                       <p id="subtitulo" >
                           ¡Vamos a celebrar muy pronto el lanzamiento de nuestro nuevo sitio!
                       </p>
                               {/*<FontAwesomeIcon style={{marginLeft:'10px'}} icon={faFacebookSquare}/>*/}
                               {/*<FontAwesomeIcon style={{marginLeft:'10px'}} icon={faInstagramSquare}/>*/}
                               {/*<FontAwesomeIcon style={{marginLeft:'10px'}} icon={faLinkedin}/>*/}

                   </div>
                </div>
                <div className="contenedorTitulo">
                    <div id="margincontenedorTitulo">
                       <span id="titulo" style={{}}>PRÓXIMAMENTE</span>
                   </div>
                </div>
            </header>
        </div>
    );
}

export default App;
